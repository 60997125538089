define("webapp/locales/ru/translations", ["exports", "webapp/locales/ru/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Связаться с",
    "application.ui.flyout.user": "Контактный партнер",
    "application.ui.flyout.calendar": "Даты",
    "application.ui.flyout.jobs": "Работа",
    "application.ui.flyout.album": "СМИ",
    "application.ui.flyout.cart": "Магазин",
    //"application.ui.flyout.world": "Партнер",
    "application.ui.flyout.world": "Места",
    "product.navigation.accessories": "Аксессуары",
    "product.detail.highlights": "Ключевые особенности",
    "product.detail.rightProductPre": "Какой",
    "product.detail.rightProductPost": "подходит ли он мне?",
    "product.detail.requestConsulting": "Консультация, запрос, демонстрация?",
    "product.detail.atWork": "на работе",
    "product.form.title": "{{product}} Запрос и организация демонстрационной встречи",
    "product.form.noForm": "Свяжитесь с нами и договоритесь о встрече для демонстрации",
    "account.ui.company": "Связаться с",
    "account.ui.companyContact": "Адрес компании",
    "account.ui.companyAddOn": "Расширенные данные",
    "account.ui.companyWant": "Подать заявку на прохождение аудита в качестве бизнес-партнера SILOKING",
    "account.company.address.isDefault": "Адрес - стандартный адрес для реквизитов компании",
    "account.ui.companyType": "Тип компании",
    "account.company.contactName": "Лицо по умолчанию для контакта",
    "account.ui.company.type1": "Дилеры сельскохозяйственной техники",
    "account.ui.company.type2": "Продажа сельскохозяйственной техники и мастерская",
    "account.ui.company.type3": "Посредник",
    "downloads.sizesInfo": "Изображение выводится с фиксированной шириной. Высота рассчитывается по соотношению сторон.",
    'social.noGdpr.info': 'Внешнее содержимое не может быть отображено, поскольку вы не дали согласия на передачу данных',
    'social.noGdpr.link': 'Открыть внешний контент на {{origin}} в новом окне.',
    'social.noGdpr.disclaimer': 'При просмотре внешнего контента данные передаются третьим лицам'
  });
});