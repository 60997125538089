define("webapp/locales/en/translations", ["exports", "webapp/locales/en/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contact",
    "application.ui.flyout.user": "Contact partner",
    "application.ui.flyout.calendar": "Dates",
    "application.ui.flyout.jobs": "Jobs",
    "application.ui.flyout.album": "Media",
    "application.ui.flyout.cart": "Shop",
    //"application.ui.flyout.world": "Partner",
    "application.ui.flyout.world": "Locations",
    "product.navigation.accessories": "Accessories",
    'product.detail.highlights': 'Highlights',
    "product.detail.rightProductPre": "Which",
    "product.detail.rightProductPost": "is the right one for me?",
    "product.detail.requestConsulting": "Consultation, inquiry, demonstration appointment?",
    "product.detail.atWork": "at work",
    "product.form.title": "{{product}} request and arrange demonstration appointment",
    "product.form.noForm": "Get in touch and arrange a demonstration appointment",
    "account.ui.company": "Contact",
    "account.ui.companyContact": "Company address",
    "account.ui.companyAddOn": "Extended data",
    "account.ui.companyWant": "Apply for audit as SILOKING Business Partner",
    "account.company.address.isDefault": "The address is the standard address for the company details",
    "account.ui.companyType": "Company type",
    "account.company.contactName": "Default person for contact",
    "account.ui.company.type1": "Agricultural machinery dealers",
    "account.ui.company.type2": "Agricultural machinery sales and workshop",
    "account.ui.company.type3": "Intermediary",
    "downloads.sizesInfo": "Image is output with fixed width. The height is calculated from the aspect ratio.",
    'social.noGdpr.info': 'External content cannot be displayed because you have not consented to the transfer of data',
    'social.noGdpr.link': 'Open external content on {{origin}} in new window.',
    'social.noGdpr.disclaimer': 'When viewing external content, data is transferred to third parties.'
  });
});