define("webapp/locales/zh/translations", ["exports", "webapp/locales/zh/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "联系我们",
    "application.ui.flyout.user": "联系合作伙伴",
    "application.ui.flyout.calendar": "日期",
    "application.ui.flyout.jobs": "工作机会",
    "application.ui.flyout.album": "媒体",
    "application.ui.flyout.cart": "淘宝店",
    //"application.ui.flyout.world": "合作伙伴",
    "application.ui.flyout.world": "场所",
    "product.navigation.accessories": "辅料",
    "product.detail.highlights": "亮点",
    "product.detail.rightProductPre": "其中",
    "product.detail.rightProductPost": "是适合我的吗？",
    "product.detail.requestConsulting": "咨询、询问、预约演示？",
    "product.detail.atWork": "在工作中",
    "product.form.title": "{{product}}申请并安排演示预约",
    "product.form.noForm": "联系并安排演示预约",
    "account.ui.company": "联系我们",
    "account.ui.companyContact": "公司地址",
    "account.ui.companyAddOn": "扩展数据",
    "account.ui.companyWant": "申请作为SILOKING业务伙伴的审计",
    "account.company.address.isDefault": "该地址是公司详细信息的标准地址",
    "account.ui.companyType": "公司类型",
    "account.company.contactName": "默认的联系人",
    "account.ui.company.type1": "农业机械经销商",
    "account.ui.company.type2": "农业机械销售和车间",
    "account.ui.company.type3": "中介人",
    "downloads.sizesInfo": "图像是以固定宽度输出的。高度是由长宽比计算出来的。",
    'social.noGdpr.info': '无法显示外部内容，因为你没有同意数据的传输',
    'social.noGdpr.link': '在新窗口打开{{origin}}上的外部内容。',
    'social.noGdpr.disclaimer': '查看外部内容时，数据会被传输给第三方。'
  });
});