define("webapp/locales/pt-br/translations", ["exports", "webapp/locales/pt-br/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contato",
    "application.ui.flyout.user": "Parceiro de contato",
    "application.ui.flyout.calendar": "Datas",
    "application.ui.flyout.jobs": "Empregos",
    "application.ui.flyout.album": "Mídia",
    "application.ui.flyout.cart": "Loja",
    //"application.ui.flyout.world": "Parceiro",
    "application.ui.flyout.world": "Locais",
    "product.navigation.accessories": "Acessórios",
    'product.detail.highlights': 'Destaques',
    "product.detail.rightProductPre": "Que",
    "product.detail.rightProductPost": "é o certo para mim?",
    "product.detail.requestConsulting": "Consulta, consulta, consulta de demonstração?",
    "product.detail.atWork": "no trabalho",
    "product.form.title": "{{product}} Solicite e marque uma consulta de demonstração",
    "product.form.noForm": "Entre em contato e marque uma visita de demonstração",
    "account.ui.company": "Contato",
    "account.ui.companyContact": "Endereço da empresa",
    "account.ui.companyAddOn": "Dados ampliados",
    "account.ui.companyWant": "Solicitar auditoria como SILOKING Business Partner",
    "account.company.address.isDefault": "O endereço é o endereço padrão para os detalhes da empresa",
    "account.ui.companyType": "Tipo de empresa",
    "account.company.contactName": "Pessoa predefinida para contato",
    "account.ui.company.type1": "Comerciantes de máquinas agrícolas",
    "account.ui.company.type2": "Venda de máquinas agrícolas e oficina",
    "account.ui.company.type3": "Intermediário",
    "downloads.sizesInfo": "A imagem é produzida com uma largura fixa. A altura é calculada a partir da relação de aspecto.",
    'social.noGdpr.info': 'O conteúdo externo não pode ser exibido porque você não consentiu com a transferência de dados',
    'social.noGdpr.link': 'Abrir conteúdo externo em {{{origem}} em nova janela',
    'social.noGdpr.disclaimer': 'Ao visualizar conteúdo externo, os dados são transferidos para terceiros'
  });
});