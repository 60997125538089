define("webapp/locales/es/translations", ["exports", "webapp/locales/es/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contacte con",
    "application.ui.flyout.user": "Contacto con el socio",
    "application.ui.flyout.calendar": "Fechas",
    "application.ui.flyout.jobs": "Empleos",
    "application.ui.flyout.album": "Medios",
    "application.ui.flyout.cart": "Tienda",
    //"application.ui.flyout.world": "Socio",
    "application.ui.flyout.world": "Localidades",
    "product.navigation.accessories": "Accesorios",
    'product.detail.highlights': 'Highlights',
    "product.detail.rightProductPre": "Que",
    "product.detail.rightProductPost": "¿es el adecuado para mí?",
    "product.detail.requestConsulting": "¿Consulta, consulta, cita de demostración?",
    "product.detail.atWork": "en el trabajo",
    "product.form.title": "{{product}} Solicite y concierte una cita de demostración",
    "product.form.noForm": "Póngase en contacto y concierte una cita de demostración",
    "account.ui.company": "Contacte con",
    "account.ui.companyContact": "Dirección de la empresa",
    "account.ui.companyAddOn": "Información ampliada",
    "account.ui.companyWant": "Solicite una auditoría como socio comercial de SILOKING",
    "account.company.address.isDefault": "La dirección es la dirección estándar de los datos de la empresa",
    "account.ui.companyType": "Tipo de empresa",
    "account.company.contactName": "Persona de contacto por defecto",
    "account.ui.company.type1": "Concesionarios de maquinaria agrícola",
    "account.ui.company.type2": "Venta y taller de maquinaria agrícola",
    "account.ui.company.type3": "Intermediario",
    "downloads.sizesInfo": "La imagen sale con una anchura fija. La altura se calcula a partir de la relación de aspecto.",
    'social.noGdpr.info': 'No se puede mostrar el contenido externo porque no has consentido la transferencia de datos',
    'social.noGdpr.link': 'Abrir contenido externo en {{origin}} en una ventana nueva',
    'social.noGdpr.disclaimer': 'Al ver contenido externo, se transfieren datos a terceros.'
  });
});