define('webapp/locales/fi/translations', ['exports', 'webapp/locales/fi/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'application.ui.flyout.receiver': 'Ota yhteyttä',
    'application.ui.flyout.user': 'Yhteistyökumppani',
    'application.ui.flyout.calendar': 'Päivämäärät',
    'application.ui.flyout.jobs': 'Työpaikat',
    'application.ui.flyout.album': 'Media',
    'application.ui.flyout.cart': 'Kauppa',
    'application.ui.flyout.world': 'Toimipaikat',
    'product.navigation.accessories': 'Tarvikkeet',
    'product.detail.highlights': 'Highlights',
    'product.detail.rightProductPre': 'Mikä',
    'product.detail.rightProductPost': 'on oikea minulle?',
    'product.detail.requestConsulting': 'Konsultointi, tiedustelu, esittelyn tapaaminen?',
    'product.detail.atWork': 'työssä',
    'product.form.title': '{{product}} Pyydä ja sovi esittelyaika',
    'product.form.noForm': 'Ota yhteyttä ja sovi esittelyn tapaaminen',
    'account.ui.company': 'Ota yhteyttä',
    'account.ui.companyContact': 'Yrityksen osoite',
    'account.ui.companyAddOn': 'Laajennetut tiedot',
    'account.ui.companyWant': 'Hakeudu SILOKING Business Partneriksi',
    'account.company.address.isDefault': 'Osoite on yrityksen tietojen vakio-osoite.',
    'account.ui.companyType': 'Yrityksen tyyppi',
    'account.company.contactName': 'Yhteyshenkilön oletushenkilö',
    'account.ui.company.type1': 'Maatalouskoneiden jälleenmyyjät',
    'account.ui.company.type2': 'Maatalouskoneiden myynti ja korjaamo',
    'account.ui.company.type3': 'Välittäjä',
    'downloads.sizesInfo': 'Kuva tulostetaan kiinteällä leveydellä. Korkeus lasketaan kuvasuhteen perusteella.',
    'social.noGdpr.info': 'Ulkoista sisältöä ei voida näyttää, koska et ole antanut suostumustasi tietojen lähettämiseen.',
    'social.noGdpr.link': 'Avaa ulkoinen sisältö {{origin}} avaa uudessa ikkunassa.',
    'social.noGdpr.disclaimer': 'Ulkoista sisältöä katseltaessa tietoja siirretään kolmansille osapuolille.'
  });
});