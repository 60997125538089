define("webapp/locales/it/translations", ["exports", "webapp/locales/it/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contatta",
    "application.ui.flyout.user": "Partner di contatto",
    "application.ui.flyout.calendar": "Date",
    "application.ui.flyout.jobs": "Lavori",
    "application.ui.flyout.album": "Media",
    "application.ui.flyout.cart": "Negozio",
    //"application.ui.flyout.world": "Partner",
    "application.ui.flyout.world": "Luoghi",
    "product.navigation.accessories": "Accessori",
    'product.detail.highlights': 'Highlights',
    "product.detail.rightProductPre": "Quale",
    "product.detail.rightProductPost": "è quello giusto per me?",
    "product.detail.requestConsulting": "Consultazione, richiesta di informazioni, appuntamento per una dimostrazione?",
    "product.detail.atWork": "al lavoro",
    "product.form.title": "{{product}} Richiedete e fissate un appuntamento per una dimostrazione",
    "product.form.noForm": "Contattate e fissate un appuntamento per una dimostrazione",
    "account.ui.company": "Contatto",
    "account.ui.companyContact": "Indirizzo dell'azienda",
    "account.ui.companyAddOn": "Informazioni estese",
    "account.ui.companyWant": "Richiesta di audit come SILOKING Business Partner",
    "account.company.address.isDefault": "L'indirizzo è quello standard per i dati dell'azienda.",
    "account.ui.companyType": "Tipo di azienda",
    "account.company.contactName": "Persona predefinita per il contatto",
    "account.ui.company.type1": "Rivenditori di macchine agricole",
    "account.ui.company.type2": "Vendita e officina di macchine agricole",
    "account.ui.company.type3": "Intermediario",
    "downloads.sizesInfo": "L'immagine viene emessa con una larghezza fissa. L'altezza viene calcolata in base al rapporto d'aspetto.",
    "social.noGdpr.info": "Il contenuto esterno non può essere visualizzato perché l'utente non ha acconsentito al trasferimento dei dati",
    "social.noGdpr.link": "Apri il contenuto esterno su {{origine}} in una nuova finestra",
    "social.noGdpr.disclaimer": "Quando si visualizzano contenuti esterni, i dati vengono trasferiti a terzi"
  });
});