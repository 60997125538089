define("webapp/locales/cs/translations", ["exports", "webapp/locales/cs/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Kontakt",
    "application.ui.flyout.user": "Kontaktní partner",
    "application.ui.flyout.calendar": "Data",
    "application.ui.flyout.jobs": "Pracovní místa",
    "application.ui.flyout.album": "Média",
    "application.ui.flyout.cart": "Obchod",
    //"application.ui.flyout.world": "Partner",
    "application.ui.flyout.world": "Lokality",
    "product.navigation.accessories": "Příslušenství",
    'product.detail.highlights': 'Highlights',
    "product.detail.rightProductPre": "Které",
    "product.detail.rightProductPost": "je pro mě ten pravý?",
    "product.detail.requestConsulting": "Konzultace, dotaz, předváděcí schůzka?",
    "product.detail.atWork": "v práci",
    "product.form.title": "{{product}} Vyžádejte si a domluvte si schůzku na předvedení",
    "product.form.noForm": "Spojte se s námi a domluvte si schůzku na předvedení",
    "account.ui.company": "Kontakt",
    "account.ui.companyContact": "Adresa společnosti",
    "account.ui.companyAddOn": "Rozšířené údaje",
    "account.ui.companyWant": "Požádejte o audit jako obchodní partner SILOKING",
    "account.company.address.isDefault": "Adresa je standardní adresa pro údaje o společnosti.",
    "account.ui.companyType": "Typ společnosti",
    "account.company.contactName": "Výchozí kontaktní osoba",
    "account.ui.company.type1": "Prodejci zemědělských strojů",
    "account.ui.company.type2": "Prodej zemědělských strojů a dílna",
    "account.ui.company.type3": "Zprostředkovatel",
    "downloads.sizesInfo": "Obrázek je vyveden s pevnou šířkou. Výška se vypočítá z poměru stran."
  });
});