define("webapp/locales/pl/translations", ["exports", "webapp/locales/pl/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Kontakt",
    "application.ui.flyout.user": "Partner kontaktowy",
    "application.ui.flyout.calendar": "Daty",
    "application.ui.flyout.jobs": "Praca",
    "application.ui.flyout.album": "Media",
    "application.ui.flyout.cart": "Sklep",
    "application.ui.flyout.world": "Lokalizacje",
    "product.navigation.accessories": "Akcesoria",
    'product.detail.highlights': 'Szczególne zalety',
    "product.detail.rightProductPre": "Który",
    "product.detail.rightProductPost": "jest dla mnie odpowiedni?",
    "product.detail.requestConsulting": "Konsultacja, zapytanie ofertowe, spotkanie pokazowe?",
    "product.detail.atWork": "w pracy",
    "product.form.title": "{{product}} Zamów i umów się na prezentację",
    "product.form.noForm": "Skontaktuj się i umów na prezentację",
    "account.ui.company": "Kontakt",
    "account.ui.companyContact": "Adres firmy",
    "account.ui.companyAddOn": "Dane rozszerzone",
    "account.ui.companyWant": "Zgłoś się na audyt jako Partner Biznesowy SILOKING",
    "account.company.address.isDefault": "Adres jest standardowym adresem dla danych firmy",
    "account.ui.companyType": "Rodzaj przedsiębiorstwa",
    "account.company.contactName": "Domyślna osoba do kontaktu",
    "account.ui.company.type1": "Sprzedawcy maszyn rolniczych",
    "account.ui.company.type2": "Sprzedaż i warsztat maszyn rolniczych",
    "account.ui.company.type3": "Pośrednik",
    "downloads.sizesInfo": "Obraz jest wyprowadzany z ustaloną szerokością. Wysokość jest obliczana na podstawie współczynnika kształtu.",
    "social.noGdpr.info": "Treści zewnętrzne nie mogą być wyświetlane, ponieważ użytkownik nie wyraził zgody na przesyłanie danych.",
    "social.noGdpr.link": "Otwórz zewnętrzną zawartość w {{origin}} otwórz w nowym oknie.",
    "social.noGdpr.disclaimer": "Podczas przeglądania treści zewnętrznych dane są przekazywane osobom trzecim."
  });
});