define('webapp/locales/de/translations', ['exports', 'webapp/locales/de/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'application.ui.flyout.receiver': 'Kontakt',
    'application.ui.flyout.user': 'Ansprech Partner',
    'application.ui.flyout.calendar': 'Termine',
    'application.ui.flyout.jobs': 'Jobs',
    'application.ui.flyout.album': 'Medien',
    'application.ui.flyout.cart': 'Shop',
    //'application.ui.flyout.world': 'Partner',
    'application.ui.flyout.world': 'Standorte',
    'product.navigation.accessories': 'Zubehör',
    'product.detail.highlights': 'Highlights',
    'product.detail.rightProductPre': 'Welcher',
    'product.detail.rightProductPost': 'ist der Richtige für mich?',
    'product.detail.requestConsulting': 'Beratung, Anfrage, Vorführtermin?',
    'product.detail.atWork': 'bei der Arbeit',
    'product.form.title': '{{product}} anfragen und Vorführtermin vereinbaren',
    'product.form.noForm': 'Nehmen Sie Kontakt auf und vereinbaren Sie einen Vorführtermin',
    'account.ui.company': 'Ansprechpartner',
    'account.ui.companyContact': 'Firmenadresse',
    'account.ui.companyAddOn': 'Erweiterte Angaben',
    'account.ui.companyWant': 'Audit als SILOKING Business-Partner beantragen',
    'account.company.address.isDefault': 'Die Anschrift ist die Standardanschrift für die Firmenangaben',
    'account.ui.companyType': 'Firmentyp',
    'account.company.contactName': 'Standardperson für Kontakt',
    'account.ui.company.type1': 'Landmaschinenhändler',
    'account.ui.company.type2': 'Landmaschinenverkauf und Werkstatt',
    'account.ui.company.type3': 'Zwischenhändler',
    'downloads.sizesInfo': 'Bild wird mit fester Breite ausgegeben. Die Höhe errechnet sich aus dem Seitenverhältnis.',
    'social.noGdpr.info': 'Externer Inhalt kann nicht angezeigt werden, da Sie der Übertragung der Daten nicht zugestimmt haben.',
    'social.noGdpr.link': 'Externen Inhalt auf {{origin}} in neuem Fenster öffnen.',
    'social.noGdpr.disclaimer': 'Beim Anzeigen externer Inhalte werden Daten an Dritte übertragen.'
  });
});