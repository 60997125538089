define("webapp/locales/ua/translations", ["exports", "webapp/locales/ua/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Контакти",
    "application.ui.flyout.user": "Контактний партнер",
    "application.ui.flyout.calendar": "Дати",
    "application.ui.flyout.jobs": "Робочі місця",
    "application.ui.flyout.album": "Засоби масової інформації",
    "application.ui.flyout.cart": "Магазин",
    "application.ui.flyout.world": "Місця розташування",
    "product.navigation.accessories": "Аксесуари",
    "product.detail.highlights": "Головні переваги",
    "product.detail.rightProductPre": "Який",
    "product.detail.rightProductPost": "чи підходить він мені?",
    "product.detail.requestConsulting": "Консультація, запит, показовий виступ?",
    "product.detail.atWork": "на роботі",
    "product.form.title": "{{product}} Замовити та домовитись про демонстраційну зустріч",
    "product.form.noForm": "Зв'яжіться з нами та домовтеся про демонстраційну зустріч",
    "account.ui.company": "Контакти",
    "account.ui.companyContact": "Адреса компанії",
    "account.ui.companyAddOn": "Розширені дані",
    "account.ui.companyWant": "Подати заявку на проведення аудиту в якості бізнес-партнера SILOKING",
    "account.company.address.isDefault": "Адреса є стандартною адресою для реквізитів компанії",
    "account.ui.companyType": "Тип компанії",
    "account.company.contactName": "Контактна особа за замовчуванням",
    "account.ui.company.type1": "Дилери сільськогосподарської техніки",
    "account.ui.company.type2": "Продаж сільськогосподарської техніки та майстерня",
    "account.ui.company.type3": "Посередник",
    "downloads.sizesInfo": "Зображення виводиться з фіксованою шириною. Висота розраховується за співвідношенням сторін.",
    "social.noGdpr.info": "Зовнішній контент не може бути відображений, оскільки ви не дали згоди на передачу даних",
    'social.noGdpr.link': 'Відкрити зовнішній вміст на {{origin}} в новому вікні',
    'social.noGdpr.disclaimer': 'При перегляді зовнішнього контенту дані передаються третім особам'
  });
});