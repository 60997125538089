define("webapp/locales/fr/translations", ["exports", "webapp/locales/fr/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contactez",
    "application.ui.flyout.user": "Partenaires de contact",
    "application.ui.flyout.calendar": "Dates",
    "application.ui.flyout.jobs": "Emplois",
    "application.ui.flyout.album": "Médias",
    "application.ui.flyout.cart": "Boutique",
    //"application.ui.flyout.world": "Partenaire",
    "application.ui.flyout.world": "Sites",
    "product.navigation.accessories": "Accessoires",
    'product.detail.highlights': 'Points forts',
    "product.detail.rightProductPre": "Quel",
    "product.detail.rightProductPost": "est la bonne pour moi ?",
    "product.detail.requestConsulting": "Conseil, demande, date de démonstration ?",
    "product.detail.atWork": "au travail",
    "product.form.title": "{{product}} demander et convenir d'une date de démonstration",
    "product.form.noForm": "Contactez-nous pour convenir d'une date de démonstration",
    "account.ui.company": "Personne de contact",
    "account.ui.companyContact": "Adresse de la société",
    "account.ui.companyAddOn": "Informations complémentaires",
    "account.ui.companyWant": "Demander un audit en tant que partenaire commercial de SILOKING",
    "account.company.address.isDefault": "L'adresse est l'adresse par défaut pour les coordonnées de l'entreprise",
    "account.ui.companyType": "Type d'entreprise",
    "account.company.contactName": "Personne à contacter par défaut",
    "account.ui.company.type1": "Concessionnaire de machines agricoles",
    "account.ui.company.type2": "Vente de machines agricoles et atelier",
    "account.ui.company.type3": "Intermédiaire",
    "downloads.sizesInfo": "L'image est imprimée avec une largeur fixe. La hauteur est calculée en fonction du rapport hauteur/largeur.",
    "social.noGdpr.info": "Impossible d'afficher le contenu externe, car vous n'avez pas accepté le transfert des données",
    "social.noGdpr.link": "Ouvrir le contenu externe sur {{origin}} dans une nouvelle fenêtre",
    "social.noGdpr.disclaimer": "Lorsque vous affichez un contenu externe, des données sont transmises à des tiers."
  });
});